const StyleProperties = {
  font: [
    'sans-serif',
    'monospace'
  ],
  colour: [
    '#333',
    'white',
    'lightpink',//'#fedf00',
    '#fedf00',//'#0058ff',//<- blue | purple -> '#4c61ff',
    'tomato',//'#444', // change to a red-orange?
  ]
}

export default StyleProperties